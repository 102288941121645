

/* Angular Swiper Style */
// @import "swiper/scss";
// @import "swiper/scss/navigation";
// @import "swiper/css/free-mode";

@import 'mdb-ui-kit/css/mdb.min.css';
* {
    // font-family: "Inter-roman", "Moderat", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-family: 'Moderat', "Inter-roman", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    // -webkit-font-smoothing: antialiased !important;
    // -moz-osx-font-smoothing: grayscale !important;
    // font-weight: 400 !important;
    // font-display: swap !important;
}

body {
    &::-webkit-scrollbar {
        display: block;
        scrollbar-width: 5px;
        background: none;
        width: 7px;
        border-radius: 10px;
      }
    
      &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          cursor: pointer;
    }    

}
.title-case {
    text-transform: lowercase;
  }
  
  .title-case::first-letter {
    text-transform: uppercase;
  }
.pull-right {
    float: right!important;
}
.no-shadow {
    box-shadow: none !important;
}

.fs-10 {
    font-size: 10px !important;
}
.fs-12 {
    font-size: 12px !important;
}
.fs-14 {
    font-size: 14px !important;
}
.fs-16 {
    font-size: 16px !important;
}
.fs-18 {
    font-size: 18px !important;
}
.fs-20 {
    font-size: 20px !important;
}
.fs-22 {
    font-size: 22px !important;
}
.fs-25 {
    font-size: 25px !important;
}
.fs-30 {
    font-size: 30px !important;
}
.fs-40 {
    font-size: 40px !important;
}
.fs-50 {
    font-size: 50px !important;
}
.fs-60 {
    font-size: 60px !important;
}
.fs-70 {
    font-size: 70px !important;
}

/* Theme Font Weight */
.fw-4 {
    font-weight: 400 !important;
}
.fw-5 {
    font-weight: 500 !important;
}
.fw-6 {
    font-weight: 600 !important;
}
.fw-7 {
    font-weight: 700 !important;  
}

.bg-gray {
    background-color: var(--bs-gray-200) !important;
}

.bg-gray-light {
    background-color: var(--bs-gray-100) !important;
}

.bg-gray-dark {
    background-color: var(--bs-gray-400) !important;
}

.no-padding {
    padding: 0px !important
}
.table {
    tbody {
        cursor: pointer !important;
        tr td {
            padding-top: 6px !important;
            padding-bottom: 6px !important;
        }
    }
    thead {
        max-height: 40px !important;
        * {
            // padding: 10px !important;
            background: var(--mdb-gray-100) !important;
        }
    }
    .badge {
        font-size: 12px !important;
        padding: 5px 7px !important;
        // border: 0.5px solid #666 !important
    }
}
.card {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px -1px rgba(0,0,0,0.1) !important;
}
.note-btn {
	border: 0px !important;
}
.note-toolbar {
	background-color: #ffffff !important;
}
.note-statusbar{
	display: none !important;
}
.select2-selection__choice span {
	font-size: 14px !important;
}
.note-editor.note-frame {
	border: 0.1px solid #ccc !important;
}
// .note-toolbar {  
// 	position: absolute !important;
// }
.offcanvas-header {
    padding: 10px !important;
}


.bl-primary {
    border-left: 3px solid var(--bs-primary) !important;
    --border-left: 3px solid var(--bs-primary) !important;
}

.bl-secondary {
    border-left: 3px solid var(--bs-secondary) !important;
    --border-left: 3px solid var(--bs-secondary) !important;
}

.bl-success {
    border-left: 3px solid var(--bs-success) !important;
    --border-left: 3px solid var(--bs-success) !important;
}

.bl-warning {
    border-left: 3px solid var(--bs-warning) !important;
    --border-left: 3px solid var(--bs-warning) !important;
}