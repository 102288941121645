/* You can add global styles to this file, and also import other style files */
@import "./assets/theme/theme.scss";
@import "./assets/theme/font.scss";
@import "./assets/theme/sidebar.scss";

.dataTables_length,
.dataTables_info,
.dataTables_filter,
.dataTables_paginate {
  margin: 0px 20px !important;
}
.form-stacked {
  .form-label {
    padding: 0px 5px;
    margin-left: 10px;
    font-size: 12px;
    background-color: #fff;
  }
  .select2-selection__choice {
    background: var(--mdb-white) !important;
    padding: 3px 5px !important;
    margin-bottom: 5px !important;
  }
  .select2-selection__choice__remove {
    font-size: 20px !important;
    color: var(--mdb-danger) !important;
    margin-right: 7px !important;
  }
  .select2-container--default,
  .select2-selection--single,
  .select2-selection--multiple {
    margin-top: 7px;
    border: 0px !important;
    background: transparent !important;
  }
  .select2-selection--multiple {
    .select2-selection__placeholder {
      margin-top: 0px !important;
    }
  }
  .selection__rendered {
    padding-left: 5px !important;
  }
  .form-select {
    padding-left: 15px;
    padding-top: 7px;
  }
  .form-control {
    min-height: 45px;
    height: auto;
    margin-top: -19px;
    padding-left: 15px;
  }
}

/** Fade in animation **/
.fadeIn {
  animation: FadeIn 0.3s ease-in forwards !important;
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
