

:root {
    --sidebar-color: #161a2d !important;
    // --sidebar-color: #ffffff !important;
    // --sidebar-color: #1679AB !important;
    --text-color: var(--mdb-white) !important;
    // --text-color: var(mdb-dark)
}
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 270px;
    display: flex;
    z-index: 1040 ;
    overflow-x: hidden;
    flex-direction: column;
    background: var(--sidebar-color);
    // background: var(--mdb-white);
    transition: all 0.4s ease;
    &::-webkit-scrollbar {
        display: none;
    }
    * {
        color: var(--text-color);
    }
    .title {
        color: var(--mdb-gray);
    }
    a.pull-right {
        margin-top: -40px; 
        margin-right: -10px;
    }
    header, footer {
        height: 58px ;
        padding: 0px 20px ;
        width: 270px;
        background: var(--sidebar-color);
        // background: var(--mdb-white);
    }
    > .list-group {
        padding: 50px 10px 0;
        .accordion-body {
            padding-left: 10px !important;
            margin-top: -10px !important;
        }
        .list-group-item {
            background: transparent;
            &.active {
                border-radius: 5px;
                background-color: var(--mdb-list-group-active-bg);
                * {
                    color: var(--mdb-dark);
                }
            }
            span {
                font-size: 15px ;
                font-weight:500;
                margin-left: 15px ;
            }
            img {
                width: 20px !important;
                height: 20px !important;
            }
            i {
                font-size: 20px ;
                position: relative;
                top: 3px;
                left: -2px;
            }
        }
        .title {
            // border-top: 0.5px solid #ccc ;
            padding-left: 20px ;
            font-weight: 550 ;
            font-size: 14px ;
            // margin-top: 10px ;
            padding-top: 30px ;
            // padding-bottom: px ;
        }
        .badge {
            float: right ;
            font-weight: bolder ;
            font-size: 14px ;
            position: relative;
            bottom: -3px;
        }
    }

    
    .user-profile {
        display: flex;
        align-items: center;
        position: relative;
        bottom: 10px;
        img {
          width: 40px !important;
          border-radius: 50%;
          margin-bottom: 5px;
        }
        .user-detail {
          margin-left: 5px;
          white-space: nowrap;
          h3 {
            font-size: 1rem;
            margin-bottom: -10px;
          }
          span {
            font-size: 0.775rem;
          }
        }
      }
}


#hasSidebar {
    .main-content {
        margin-left: 290px;
        margin-top: 70px; 
        width: calc(100vw - 300px);
    }
    &.menuClosed {
        .sidebar {
            width: 85px;
            > .list-group {
                margin-top: -10px !important;
                .title {
                    font-size: 0px;
                    margin-top: -10px !important;
                }
            }
            header, footer {
                width: 85px;
            }
            i {
                margin-left: -2px;
                font-size: 23px;
            }
            a.pull-right {
                margin-top: 20px;
                margin-right: 10px;
            }
            span, .navbar, .user-profile h3, .user-profile span, i.pull-right {
                display: none ;
            }
        }
        .main-content { 
            margin-left: 100px;
            margin-top: 70px; 
            width: calc(100vw - 120px);
        }
    }
}
