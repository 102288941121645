

@font-face {
    font-family: 'Inter-roman';
    src: url('../fonts/Inter-roman.var.woff2') format('woff2');
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat-Regular.a2f5d8f.ttf') format('truetype');
}
@import url("https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css");
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
